import Script from 'next/script'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { useState } from 'react'

let zdonload
const SupportWidget = () => {
    const authUser = useSelector(({ authUser }) => authUser)
    const router = useRouter()
    const currentPath = router.pathname
    const [showReal, setShowReal] = useState(false)
    const [showFake, setShowFake] = useState(true)

    const handleFakeClick = () => {
        setShowReal(true)
        zdonload = setInterval(function () {
            if (typeof window.zE !== 'undefined') {
                clearInterval(zdonload)
                zE('webWidget', 'open');
                setShowFake(false)
            }
        }, 50, null)
    }

    return <>
        {(!authUser || currentPath.startsWith('/onboarding')) ? <style>
            {`#launcher {display: none;}`}
        </style> : ''}
        <style>
            {`.grecaptcha-badge {bottom: 70px!important;}`}
        </style>
        {
            showReal && <Script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=e8206e5f-dd40-484f-8c73-ee445faad6b8"
            />
        }
        {
            showFake &&
            <button className="fake-zendesk-button" onClick={handleFakeClick}>
                <span className="fake-zendesk-button-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20">
                        <g id="Layer_4">
                            <path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"/>
                            <circle cx="10" cy="15" r="1"/>
                            <path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"/>
                        </g>
                    </svg>
                </span>
                <span className="fake-zendesk-button-text">Help</span>
            </button>
        }
    </>
}

export default SupportWidget
